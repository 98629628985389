import "./App.css";
import React, { useState } from "react";
import AOS from "aos";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home/Home";
import axios from "axios";
import { useEffect } from "react";
import { Reservation } from "./pages/Reservation/Reservation";
import { Admin } from "./pages/Admin/Admin";
import ProtectedRoute from "./ProtectedRoute"; // Import if it's in a separate file
import Login from "./pages/Login/Login";


function App() {
  AOS.init();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const data = await axios.get(process.env.REACT_APP_API_URL, {
        //   Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
        // });
        const data = await axios.get(process.env.REACT_APP_API_URL);

        console.log(data.data.data[1].s);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/rezervace" element={<Reservation />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <Admin />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
