import React from "react";
import "./calendar.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useState } from "react";
import { csCZ } from "@mui/x-date-pickers";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import axios from "axios";
import { useEffect } from "react";
import "dayjs/locale/cs"; // Import Czech locale
import ContentCutIcon from "@mui/icons-material/ContentCut";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HourglassEmpty from "@mui/icons-material/HourglassEmpty";

dayjs.extend(localizedFormat);
dayjs.locale("cs"); // Use Czech locale globally

//TODO: POŘEŠIT S CHATGPT jak zamezit dvojitým rezervacím - otestovat to.

export const Calendar = ({
  setSelectedDateRecord,
  setSelectedTimeRecord,
  serviceTimeTotal,
  servicesTotalPrice,
  setStep,
  bookedDates,
  selectedService,
  selectedAdditionalServices,
  additionalServicesTimeTotal,
}) => {
  const openingHours = "10:30";
  const closingHours = "20:00";

  const convertTimeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const convertMinutesToTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
  };
  
  const getTimeBlocks = (date, serviceTimeTotal) => {
    if (serviceTimeTotal === 25) serviceTimeTotal = 30;
    const formattedSelectedDate = date.format("DD.MM.YYYY");
    let opening = convertTimeToMinutes(openingHours);
    let closing = convertTimeToMinutes(closingHours);
  
    const dayOfWeek = date.day();
  
    const lunchStart = convertTimeToMinutes("12:15");
    const lunchEnd = convertTimeToMinutes("13:00");
    const dinnerStart = convertTimeToMinutes("17:15");
    const dinnerEnd = convertTimeToMinutes("17:30");
    const saturdayOpen = convertTimeToMinutes("11:00");
    const saturdayClose = convertTimeToMinutes("15:15");
  
    if (serviceTimeTotal > 90 && dayOfWeek !== 6) {
      opening = convertTimeToMinutes("13:00");
    }
  
    const blocks = [];
  
    for (
      let time = opening;
      time <= closing - serviceTimeTotal;
      time += serviceTimeTotal
    ) {
      const endTime = time + serviceTimeTotal;
      if (endTime > closing) break;
  
      let formattedStartTime = convertMinutesToTime(time);
      const formattedEndTime = convertMinutesToTime(endTime);
  
      if (
        (time < lunchEnd && endTime > lunchStart) ||
        (time < dinnerEnd && endTime > dinnerStart) ||
        (time < lunchStart && endTime > lunchEnd) ||
        (time < dinnerStart && endTime > dinnerEnd)
      ) {
        continue;
      }
  
      if (dayOfWeek === 6) {
        if (time < saturdayOpen || endTime > saturdayClose) continue;
      }
  
      const isBooked = bookedDates.some((booking) => {
        if (booking.date !== formattedSelectedDate) return false;
  
        const bookingStart = convertTimeToMinutes(booking.startTime);
        const bookingEnd = convertTimeToMinutes(booking.finishTime);
  
        return time < bookingEnd && endTime > bookingStart;
      });
  
      if (!isBooked) {
        blocks.push(`${formattedStartTime}`);
      }
    }
    return blocks;
  };
  
  const hasAvailableTimeBlocks = (checkDate, serviceTimeTotal) => {
    const formattedDate = checkDate.format("DD.MM.YYYY");
    const dayOfWeek = checkDate.day();
  
    let openingTime = openingHours;
    let closingTime = closingHours;
  
    const lunchStart = convertTimeToMinutes("12:15");
    const lunchEnd = convertTimeToMinutes("13:00");
    const dinnerStart = convertTimeToMinutes("17:15");
    const dinnerEnd = convertTimeToMinutes("17:30");
    if (dayOfWeek === 6) {
      openingTime = "11:00";
      closingTime = "15:15";
    }
    if (serviceTimeTotal > 90 && dayOfWeek !== 6) {
      openingTime = "13:00";
    }
  
    let opening = convertTimeToMinutes(openingTime);
    let closing = convertTimeToMinutes(closingTime);
  
    for (
      let startTime = opening;
      startTime <= closing - serviceTimeTotal;
      startTime += serviceTimeTotal
    ) {
      let endTime = startTime + serviceTimeTotal;
  
      if (
        (startTime < lunchEnd && endTime > lunchStart) ||
        (startTime < dinnerEnd && endTime > dinnerStart) ||
        (startTime < lunchStart && endTime > lunchEnd) ||
        (startTime < dinnerStart && endTime > dinnerEnd)
      ) {
        continue;
      }
  
      const isBlocked = bookedDates.some((booking) => {
        if (booking.date !== formattedDate) return false;
        const bookingStart = convertTimeToMinutes(booking.startTime);
        const bookingEnd = convertTimeToMinutes(booking.finishTime);
        return startTime < bookingEnd && endTime > bookingStart;
      });
  
      if (!isBlocked) {
        return true;
      }
    }
  
    return false;
  };
  
  // Function to find the next available date based on service time total
  const findNextAvailableDate = (startDate, serviceTime) => {
    let checkDate = dayjs(startDate);
    // Loop until an available date is found that is not on a Sunday or Monday
    while (
      !hasAvailableTimeBlocks(checkDate, serviceTime) ||
      checkDate.day() === 0 ||
      checkDate.day() === 1
    ) {
      checkDate = checkDate.add(1, "day");
    }
    return checkDate;
  };

  const [date, setDate] = useState(dayjs());
  const [selectedDate, setSelectedDate] = useState(
    findNextAvailableDate(dayjs(date), serviceTimeTotal)
  );
  const [availableTimeBlocks, setAvailableTimeBlocks] = useState([]);
  const minDate = findNextAvailableDate(dayjs(date), serviceTimeTotal);
  const disabledDates = [];

  useEffect(() => {
    setSelectedDateRecord(selectedDate);
  }, []);
  useEffect(() => {
    // Check if the date is disabled, directly passing the date to be checked
    if (shouldDisableDate(date)) {
      // If the date is disabled, move to the next day
      setDate(date.add(1, "day"));
    }
  }, [date]);
  useEffect(() => {
    // Ensure selectedDate is always a dayjs object
    if (selectedDate && selectedDate.format) {
      const newAvailableTimeBlocks = getTimeBlocks(
        selectedDate,
        serviceTimeTotal
      );
      setAvailableTimeBlocks(newAvailableTimeBlocks);
    }
  }, [selectedDate, serviceTimeTotal]);

  const handleDateChange = (newDate) => {
    setSelectedDate(dayjs(newDate)); // This should ensure 'selectedDate' is always a dayjs object
    setSelectedDateRecord(dayjs(newDate));
  };
  const handleTimeBlockClick = (timeBlock) => {
    setSelectedTimeRecord(timeBlock);
    setStep(3);
  };
  const shouldDisableDate = (date) => {
    const dayjsDate = dayjs(date);
    const isSundayOrMonday = dayjsDate.day() === 0 || dayjsDate.day() === 1;
    const isDisabledDate = disabledDates.some((disabledDate) =>
      dayjsDate.isSame(dayjs(disabledDate, "DD.MM.YYYY"), "day")
    );
    const noAvailableBlocks = !hasAvailableTimeBlocks(
      dayjsDate,
      serviceTimeTotal
    );

    return isSundayOrMonday || isDisabledDate || noAvailableBlocks;
  };

  return (
    <div className="reservationBlock">
      <h2 className="reservationTitle"> Zvolte datum a čas služby</h2>

      <div className="calendarWrapper">
        <div className="datePicker">
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="cs"
            localeText={
              csCZ.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DateCalendar
              label="Date"
              inputFormat="DD.MM.YYYY"
              fixedWeekNumber={6}
              shouldDisableDate={shouldDisableDate}
              views={["day", "month"]}
              date={findNextAvailableDate(dayjs(), serviceTimeTotal)}
              minDate={findNextAvailableDate(dayjs(), serviceTimeTotal)}
              maxDate={dayjs().add(60, "day")}
              disableHighlightToday
              value={selectedDate}
              onChange={handleDateChange}
              showDaysOutsideCurrentMonth
            />
          </LocalizationProvider>
        </div>
        <div className="timeBlocksWrapper">
          <div className="timeBlocks">
            {availableTimeBlocks.length > 0 &&
              availableTimeBlocks.map((block, index) => (
                <div
                  key={index}
                  className="timeBlock"
                  onClick={() => handleTimeBlockClick(block)}
                >
                  {block}
                </div>
              ))}
          </div>
        </div>
      </div>
      {selectedService.nazev && (
        <div className="calendar-overview">
          <ContentCutIcon className="overViewIconCal" />{" "}
          <span>
            Vybraná služba:<b> {selectedService.nazev}</b>
            <CalendarMonthIcon className="overViewIconCal" />
            Datum: <b>{selectedDate.format("DD.MM.YYYY")}</b>
            <HourglassEmpty className="overViewIconCal" />
            Délka návštěvy <b>{serviceTimeTotal}min</b>
            <AttachMoneyIcon className="overViewIconCal" />
            Cena: <b>{selectedService.cena + servicesTotalPrice} Kč</b>
          </span>
        </div>
      )}
    </div>
  );
};
